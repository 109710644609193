<template>
  <MoleculesTitleCard heading="編集部ピックアップ">
    <NuxtLink
      v-for="item in items"
      :key="item.permalink!"
      :to="createArticleLink(item.permalink!)"
      class="flex items-center p-3 not-last:border-gray-300 not-last:border-b hover:underline hover:opacity-50 duration-300"
    >
      <div class="shrink-0 w-[64px] rounded mr-2 overflow-hidden">
        <img
          v-if="item.image_file"
          :src="createArticleThumbImagePath(item.image_file)"
        />
        <AtomsPlaceholderImage v-else class="w-full" />
      </div>
      <span class="grow">
        <span>{{ item.title }}</span>
        <span
          v-if="isPrArticle(item)"
          class="border border-current text-sm p-0.5 ml-2"
          >PR</span
        >
      </span>
      <img src="~/assets/img/icon_arrow-square.png" />
    </NuxtLink>
  </MoleculesTitleCard>
</template>

<script lang="ts" setup>
import type { GetPickupArticlesQueryArticleExtended } from '@shared/types/kiwiUserServer';
import { createArticleThumbImagePath } from '@shared/utils/createArticleImagePath';

const { isPrArticle } = await useCategories();

defineProps({
  items: {
    type: Array as PropType<GetPickupArticlesQueryArticleExtended[]>,
    required: true,
  },
});
</script>
